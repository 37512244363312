/* Banner Quiz Component JS File */
$(document).ready(function() {
	if ($(".banner-quiz-wrapper").length > 0) {
		$(".banner-quiz-wrapper .section-start-box .trigger-start-box").on('click', function() {
			// if ($(".banner-quiz-wrapper .section-start-box").hasClass('active')) {
				// $($(this).attr('data-start-box')).removeClass('active');
				$($(this).attr('data-banner-quiz')).addClass('active');
			// }
		});

		$(".banner-quiz-wrapper .section-banner-quiz .trigger-close-box").on('click', function() {
			if ($(".banner-quiz-wrapper .section-banner-quiz").hasClass('active')) {
				// $($(this).attr('data-start-box')).removeClass('active');
				$($(this).attr('data-banner-quiz')).removeClass('active');
			}
		});
	}
});
