/* Form Box Block JS File */
$(document).ready(function() {
	if ($(".form-box-block").length > 0) {
		$(".form-box-block .section-start-box .trigger-start-box").on('click', function() {
			// if ($(".form-box-block .section-start-box").hasClass('active')) {
				// $($(this).attr('data-start-box')).removeClass('active');
				$($(this).attr('data-form-box')).addClass('active');
			// }
		});
		$(".form-box-block .section-form-box .trigger-close-box").on('click', function() {
			if ($(".form-box-block .section-form-box").hasClass('active')) {
				// $($(this).attr('data-start-box')).removeClass('active');
				$($(this).attr('data-form-box')).removeClass('active');
			}
		});
	}
});
